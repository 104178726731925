<template>
  <div>
    <Header
      titleurl="jobwanted"
      :iscontactTel="iscontactTel"
      v-on:closeShow="closeShow"
    ></Header>
    <div class="center">
      <div class="ment">
        <div class="mentLeft">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/jobwanted' }"
              >牛豆豆求职</el-breadcrumb-item
            >
            <el-breadcrumb-item>简历详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- <div class="mentRight">
          <el-button type="primary" size="mini" class="button" @click="getlist()">发布招聘</el-button>
          <el-button type="primary" size="mini" class="button" @click="resumTo()">登记简历</el-button>
        </div> -->
      </div>
    </div>
    <div class="center">
      <div class="boxs">
        <div class="portrait">
          <!-- 介绍简历 -->
          <div class="msg">
            <div class="portraits">
              <!-- 头像 -->
              <img
                class="images"
                src="../../assets/img/timgs.jpg"
                v-if="images"
              />
              <img class="images" :src="avatarIdPath" v-else />
            </div>
            <!-- 姓名 手机，身份证 认证 -->
            <div class="message">
              <div class="message_RightBox">
                <div class="message_name">{{ getLists.username }}</div>
                <div class="message_phone">
                  <div class="message_phoneIcon">
                    <img
                      src="../../assets/img/iphone.png"
                      class="Message_icon"
                    />
                    <span class="Message_text">手机认证</span>
                  </div>
                  <div class="message_phoneId">
                    <img
                      src="../../assets/img/IDcard.png"
                      class="Message_icons"
                    />
                    <span class="Message_text">身份认证</span>
                  </div>
                </div>
              </div>
              <!-- 学历，年龄 -->
              <div class="message_shool">
                {{ getLists.sex }}
                <span>|</span>
                <span>{{ getLists.age }}岁</span>
                <span>|</span>
                <span>{{ getLists.education }}</span>
                <span>|</span>
                <span>工作时间：{{ getLists.workExp }}年</span>
              </div>
              <!-- 求职状态 -->
              <div class="message_code">
                <span class="message_codeTitle">求职状态:</span>
                <span class="jebCode">{{ getLists.workConditions }}</span>
              </div>
              <!-- 期望职位 -->
              <div class="message_code">
                <span class="message_codeTitle">期望职位:</span>
                <span class="jebCode">{{ getLists.expectJobName }}</span>
              </div>
              <!-- 期望地点 -->
              <div class="message_code">
                <span class="message_codeTitle">期望地点:</span>
                <span class="jebCode">{{ getLists.workCity }}</span>
              </div>
              <!-- 期望薪资 -->
              <div class="message_code">
                <span class="message_codeTitle">期望薪资:</span>
                <span class="jebCode">{{ getLists.expectSalary }}</span>
              </div>
              <div class="message_qq">
                <el-button
                  type="danger"
                  icon="el-icon-phone"
                  @click="phoneNmuber"
                  >电话联系</el-button
                >
                <!-- <el-button type="primary" icon="el-icon-chat-dot-square"
                  >在线沟通</el-button
                > -->
              </div>
            </div>
          </div>
          <!-- 工作经验 -->
          <div class="shool">
            <span class="shool_title">工作经历</span>
            <div
              class="jobEpx"
              v-for="(item, index) in jobExperienceList"
              :key="index"
            >
              <span class="jebExperience">{{ item.companyName }}</span>
              <div class="shool_name">
                <span>工作时间：</span>
                <span>{{ item.starDate }} ~ {{ item.endDate }}</span>
              </div>
              <div class="shool_name">
                <span>职位名称：</span>
                <span>{{ item.positionName }}</span>
              </div>
              <div class="shool_name">
                <span>薪资：</span>
                <span>{{ item.salary }}</span>
              </div>
              <div class="shool_name">
                <span>工作职责：</span>
                <div class="shool_text">{{ item.jobContent }}</div>
              </div>
            </div>
          </div>
          <!-- 教育经历 -->
          <div class="shool">
            <span class="shool_title">教育经历</span>
            <div
              class="jobEpx"
              v-for="(item, index) in educationExperienceList"
              :key="index"
            >
              <span class="jebExperience">{{ item.schoolName }}</span>
              <div class="shool_name">
                <span>毕业时间：</span>
                <span>{{ item.starDate }} ~ {{ item.endDate }}</span>
              </div>
              <div class="shool_name">
                <span>专业：</span>
                <span>{{ item.majorName }}</span>
              </div>
              <div class="shool_name">
                <span>在校经历：</span>
                <div class="shool_text">{{ item.schoolExperience }}</div>
              </div>
            </div>
          </div>
          <!-- 项目经验-->
          <div class="shool">
            <span class="shool_title">项目经验</span>
            <div
              class="jobEpx"
              v-for="(item, index) in projectExperienceList"
              :key="index"
            >
              <span class="jebExperience">{{ item.projectName }}</span>
              <div class="shool_name">
                <span>项目时间：</span>
                <span>{{ item.starDate }} ~ {{ item.endDate }}</span>
              </div>
              <div class="shool_name">
                <span>职位名称：</span>
                <span>{{ item.positionName }}</span>
              </div>
              <div class="shool_name">
                <span>项目介绍：</span>
                <div class="shool_text">{{ item.projectIntroduce }}</div>
              </div>
              <div class="shool_name">
                <span>项目业绩：</span>
                <div class="shool_text">{{ item.projectAchievement }}</div>
              </div>
            </div>
          </div>
          <!-- 自我评价 -->
          <div class="shool">
            <span class="shool_title">自我评价</span>
            <div class="shool_text">{{ getLists.selfAssessment }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="checkBox">
        <div class="checkBoxTitle">
          <span>您的同行还查看了以下人才</span>
          <img src="../../assets/img/new.png" />
        </div>
        <div class="checkBf" v-for="item in candidateLists" :key="item.id">
          <el-checkbox-group
            class="checkInputBox"
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox class="checkInput" :label="item"
              ><span></span
            ></el-checkbox>
          </el-checkbox-group>
          <div class="checkM">
            <!-- 多选左侧信息 -->
            <div class="checkboxs">
              <div class="checkboxsName" @click="getResumeviewList(item.id)">
                <span class="checkMsg">{{ item.username }}</span>
                <div>
                  <span class="checkMsgs">{{ item.sex }}</span>
                  <span class="checkMsgs">|</span>
                  <span class="checkMsgs">{{ item.age }}岁</span>
                  <span class="checkMsgs">|</span>
                  <span class="checkMsgs">{{ item.education }}</span>
                  <span class="checkMsgs">|</span>
                  <span class="checkMsgs">工作时间：{{ item.workExp }}年</span>
                </div>
              </div>
              <div class="checkJob">{{ item.expectJobName }}</div>
              <div class="checkJobMsg" v-if="checkJobMsg">
                {{ item.education }}
              </div>
            </div>
            <!-- 多选右侧信息 -->
            <div class="checkboxRight">
              <img class="checkImag" src="../../assets/img/phone.png" />
              <div class="checkRegion">
                <div class="checkRegins">
                  <i class="el-icon-location-information"></i>
                  <span>{{ item.workCity }}</span>
                </div>
                <div class="checkText">{{ item.activeDate.substr(0,10) }} 活跃</div>
                <div class="checkText">
                  简历完成度{{ item.candidateIntegrity }}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagesbox">
          <el-pagination
            class="pages"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :pager-count="7"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
        <el-checkbox
          class="checkAll"
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        >
          <template>
            <div>全选</div>
          </template>
        </el-checkbox>
        <div class="allCheck">
          <div>
            <a href="/" class="allCheckRight">
              不满意？更多海量优质人才看这里
              <i class="el-icon-d-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 手机对话框 -->
    <el-dialog title="请与我联系" :visible.sync="dialogVisible" width="300px">
      <span class="spanDialog">{{ getLists.phone }}</span>
    </el-dialog>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../../components/header';
import Footer from '../../components/footer';
import { candidate, datagridCandidate } from '@/api/resumeview';
// import { avatarIdPath } from '@/api/store';
import Utils from '../../utils/unil';
export default {
  name: 'resumeview',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      entityId: '5418',
      // 复选框
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      getLists: {},
      // 工作经历
      jobExperienceList: [],
      // 教育经历
      educationExperienceList: [],
      // 项目经历
      projectExperienceList: [],
      dialogVisible: false,
      // 当前页
      currentPage: 0,
      // 查询求职者列表参数
      param: {
        cityCode: '',
        companyId: 0,
        educationCode: '',
        expectJobs: 0,
        expectSalaryCode: '',
        isHaveAvatarId: 0,
        sexCode: '',
        workExpCode: '',
        sortRule: 0,
        keyword: '',
        pageIndex: 1,
        pageSize: 5,
      },
      // 总条数
      total: 0,
      // 查询求职者列表数据
      candidateLists: [],
      // // 每页多少条数据
      // pageSize: 0,
      // 获取头像路径
      avatarIdPath: '',
      // 学历的显示与隐藏
      checkJobMsg: false,
      // 头像的显示与隐藏
      images: false,
      iscontactTel: false,
      desCompanyId:''
    };
  },
  mounted() {
    // 路径id
    let id = this.$route.query.id;
    this.desCompanyId = this.$route.query.desCompanyId;
    this.getResumeviewList(id);
    this.candidateList();
  },
  methods: {
    // 多选框
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.candidateLists : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.candidateLists.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.candidateLists.length;
    },
    // 跳转到登记简历
    resumTo() {
      this.$router.push('/jobwanted/resume');
    },
    // 求职者简历详情数据
    async getResumeviewList(id) {
      const { data: res } = await candidate(id,this.desCompanyId);
      if (res.code == 1) {
        if(res.result.isEffective!=1){
            this.$message({
            showClose: true,
            message: '信息正在审核中',
            duration:0,
            type: 'warning'
          });
        }
        this.images = false;
        this.getLists = res.result;
        // this.getLists.avatarId = res.result.avatarId;
        this.avatarIdPath = res.result.avatarPath;
        // console.log(this.avatarIdPath);
        // 获取工作经历的数据
        this.jobExperienceList = res.result.jobExperienceList;
        // 获取工作经历的时间戳
        for (let i = 0; i < this.jobExperienceList.length; i++) {
          this.jobExperienceList[i].starDate = this.$formatUTC(
            this.jobExperienceList[i].workStartDate
          );
          this.jobExperienceList[i].endDate = this.$formatUTC(
            this.jobExperienceList[i].workEndDate
          );
        }
        // 获取教育经历的数据
        this.educationExperienceList = res.result.educationExperienceList;
        // 获取教育经历的时间戳
        for (let i = 0; i < this.educationExperienceList.length; i++) {
          this.educationExperienceList[i].starDate = this.$formatUTC(
            this.educationExperienceList[i].graduateStartDate
          );
          this.educationExperienceList[i].endDate = this.$formatUTC(
            this.educationExperienceList[i].graduateEndDate
          );
        }
        // 获取项目经验的数据
        this.projectExperienceList = res.result.projectExperienceList;
        // 获取项目经验的时间戳
        for (let i = 0; i < this.projectExperienceList.length; i++) {
          this.projectExperienceList[i].starDate = this.$formatUTC(
            this.projectExperienceList[i].projectStartDate
          );
          this.projectExperienceList[i].endDate = this.$formatUTC(
            this.projectExperienceList[i].projectEndDate
          );
        }
        // this.getAvatarIdPath();
        window.scrollTo(0, 0);
      } else {
        return this.$message.error('获取信息失败');
      }
    },
    // 手机对话框
    phoneNmuber() {
      let getlistPhone = this.getLists.phone;
      let hideReason = this.getLists.hideReason;
      if (!getlistPhone) {
        this.iscontactTel = true;
      } else {
        if (hideReason == null) {
          this.dialogVisible = false;
          this.$message.error('你还不是HR！');
        } else {
          this.dialogVisible = true;
        }
      }
    },
    // 分页
    handleSizeChange(val) {
      // console.log(val);
      this.param.pageSize = val;
      this.candidateList();
    },
    handleCurrentChange(val) {
      this.param.pageIndex = val;
      this.candidateList();
    },
    // 查询求职者列表
    async candidateList() {
      this.getLists = [];
      let param = this.param;
      const { data: res } = await datagridCandidate(param);
      if (res.code == 1) {
        this.candidateLists = res.result.list;
        // res.result.rows.forEach((item) => {
        //   if (item.education !== null) {
        //     this.checkJobMsg = true;
        //   } else {
        //     this.checkJobMsg = false;
        //   }
        // });
        this.total = res.result.totalCount;
        this.currentPage = res.result.currPageIndex;
      } else {
        return this.$message.error('查询求职者列表加载失败');
      }
    },
    //header头部传过来关闭登录框的操作
    closeShow(val) {
      this.iscontactTel = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="../../assets/style/recruit/viewresumed.css" scoped>
</style>
